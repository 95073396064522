import React, { useState, useEffect, useCallback, cloneElement } from 'react'

import { T13nProvider, useT13nContext } from '../../Context/T13nContext'

// import loadable from '@loadable/component'

import VirtualKeyboard from '../../Components/VirtualKeyboard'

import { keyboardLanguages } from '../../StaticData/SiteData.js'

// import Sticky from 'react-sticky-el'
import { Button } from '@material-ui/core'
import ActionBar from './FreeText/ActionBar'
import { LazyLoad } from '../../Components/LazyLoad'

// const LazyClient = src => ssr ? () => null : React.lazy(() => import(src))
import AdsenseAd, { AdsenseAdVisible } from '../../Components/AdsenseAd'
// const AdsenseAdLazy = React.lazy(() => import('../../Components/AdsenseAd'))

// import StickyEl from "react-sticky-el";
import dynamic from 'next/dynamic'
import { useGate2HomeContext } from '../../Context/Gate2HomeContext'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import IpadCSS from '../../Components/VirtualKeyboard/VirtualKeyboard.full.3.7.2/css/ipadCSS'
import KeyboardSectionLogoHeader from './KeyboardSectionLogoHeader'
// import MobileCSS from '../../Components/VirtualKeyboard/VirtualKeyboard.full.3.7.2/css/mobileCSS'
import { SearchProvider } from '../../Context/SearchContext'
import { KeyboardProvider } from '../../Context/KeyboardContext'
import { TextboxProvider } from '../../Context/TextboxContext'

const StickyLazy = dynamic(() => import('react-sticky-el'))
// const IpadCSS = dynamic(() => import('../../Components/VirtualKeyboard/VirtualKeyboard.full.3.7.2/css/ipadCSS'))
const MobileCSS = dynamic(() => import('../../Components/VirtualKeyboard/VirtualKeyboard.full.3.7.2/css/mobileCSS'))

// const Translate = dynamic(() => import("./Translate"));
// const EmailLazy = dynamic(() => import("./Email"));
// const VirtualKeyboard = dynamic(() => import('../../Components/VirtualKeyboard'))

// const GoogleLazy = dynamic(() => import("./Google"));
const GoogleResultsIframeLazy = dynamic(() => import('./Google/GoogleResultsIframe'))
// const WikipediaLazy = dynamic(() => import("./Wikipedia"));
const WikipediaResultsIframeLazy = dynamic(() => import('./Wikipedia/WikipediaResultsIframe'))

// const WikipediaResultsIframeLazy = ssr ? () => null : React.lazy(() => import('./Wikipedia/WikipediaResultsIframeLazy'))

/// ///////////////////

// const Translate = loadable(() =>  import('./Translate'))
// const Email = loadable(() =>  import('./Email'))
// const GoogleLazy = loadable(() =>  import('./Google/GoogleLazy'))
// const GoogleResultsIframeLazy = loadable(() =>  import('./Google/GoogleResultsIframeLazy'))
// const WikipediaLazy = loadable(() =>  import('./Wikipedia/WikipediaLazy'))
// const WikipediaResultsIframeLazy = loadable(() =>  import('./Wikipedia/WikipediaResultsIframeLazy'))

// const FreeText = React.lazy(() => import('./FreeText'));

const StickyOnDemand = ({ disabled, ...props }) => {
    const [scrolled, setScrolled] = useState(false)

    const handleScroll = useCallback(() => {
        setScrolled(true)
    }, [setScrolled])

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (!scrolled) {
                window.addEventListener('scroll', handleScroll, false)
                return () => window.removeEventListener('scroll', handleScroll, false)
            }
        }
    }, [handleScroll, scrolled])

    return scrolled && !disabled ? <StickyLazy {...props} /> : <div className='sticky'>{props.children}</div>
}

const LazyLoadT13n = ({ kbLangKey }) => {
    const [t13nState, t13nDispatch] = useT13nContext()

    return (
        <LazyLoad
            id='TransliterateBox'
            condition={t13nState.t13n}
            reactLazyFunc={() => import('../../Components/TransliterateBox')}
            kbLangKey={kbLangKey}
            t13nState={t13nState}
            t13nDispatch={t13nDispatch}
        />
    )
}

const test = typeof window !== 'undefined' ? window.g2h_test : {}

function Keyboard({ children, ...props }) {
    const { i18n, t, kbLangKey, sectionKey, q, lang, tt } = props
    const [Gate2homeState, Gate2homeDispatch] = useGate2HomeContext()

    const [clientSide, setClientSide] = useState(false)

    useEffect(() => {
        setClientSide(true)
    }, [])

    const isLess1075 = useMediaQuery('(max-width:1075px)') //less because loads on second render (wanting !isLess1075 to be true on first render, CLS-wise)
    const isMobileMedia = useMediaQuery('(max-width: 720px)')
    const isDesktopMedia = useMediaQuery('(min-width: 720px)')

    //SSR hydration causes problems, these assures mediaqueries are updated on client:
    const [mediaQueries, setMediaQueries] = useState(false)
    useEffect(() => {
        setMediaQueries({ isLess1075, isMobileMedia, isDesktopMedia })
    }, [isDesktopMedia, isLess1075, isMobileMedia])

    const [abtestVal, setAbtestVal] = useState(clientSide ? test.abtest : 0)
    useEffect(() => {
        setAbtestVal(test.abtest)
    }, [kbLangKey, sectionKey, q])

    // const [random01, setrandom01] = useState(Math.floor(Math.random() * 2) == 0 ? 'row' : 'row-reverse')
    // useEffect(() => {
    //     setrandom01(Math.floor(Math.random() * 2) == 0 ? 'row' : 'row-reverse')
    // }, [kbLangKey, sectionKey])

    // const getAbtestDirection = useCallback(
    //     () =>
    //         clientSide
    //             ? !abtestVal
    //                 ? 'row'
    //                 : abtestVal == 3
    //                 ? random01
    //                 : keyboardLanguages.data[kbLangKey].rtl
    //                 ? abtestVal == 2
    //                     ? 'row'
    //                     : 'row-reverse'
    //                 : abtestVal == 2
    //                 ? 'row-reverse'
    //                 : 'row'
    //             : undefined,
    //     [abtestVal, clientSide, kbLangKey, random01]
    // )

    // const [abtestDirection, setabtestDirection] = useState(getAbtestDirection())
    // useEffect(() => {
    //     setabtestDirection(getAbtestDirection())
    // }, [getAbtestDirection, kbLangKey, sectionKey])

    // const [test, setTest] = useState(typeof window !== 'undefined' && window.test ? window.test : {})
    // useEffect(() => {
    //     if (typeof window !== 'undefined' && Object.keys(test).length === 0) {
    //         let duration = 0
    //         const interval = setInterval(() => {
    //             duration += 50
    //             if (duration > 10000) {
    //                 clearInterval(interval)
    //             }
    //             if (window.test !== undefined) {
    //                 setTest(window.test)
    //                 clearInterval(interval)
    //                 console.log('testt: ', window.test)
    //             }
    //         }, 50)
    //     }
    // }, [test])

    useEffect(() => {
        if (q || tt || lang) {
            Gate2homeDispatch({
                type: 'update-state',
                payload: { query: { q, t: tt, lang } }
            })
        }
    }, [Gate2homeDispatch, q, tt, lang])

    const handleLoadKeyboard = useCallback(() => {
        Gate2homeDispatch({ type: 'mobile-load-keyboard' })
    }, [Gate2homeDispatch])

    return (
        <div className={keyboardLanguages.data[kbLangKey].rtl ? 'kb-rtl' : ''}>
            <style jsx global>{`
                .layout {
                    max-width: 750px;
                    width: 750px;
                }
                .layout_grid_container {
                    width: 100%;
                    display: flex;
                    box-sizing: border-box;f
                    align-items: flex-start;
                    justify-content: center;
                    flex-flow: ${(keyboardLanguages.data[kbLangKey].rtl ? 'row' : 'row-reverse') + ' wrap'};
                }
            `}</style>
            {/* dynamic styles: */}
            <style jsx global>{`
                .SearchSection .qdiv,
                .mailsect #mail_subject_div,
                .mailsect #textbox1_email_msg,
                #textbox1_freetext,
                #textbox1_translate {
                    direction: ${keyboardLanguages.data[kbLangKey].rtl ? 'rtl' : 'ltr'};
                    text-align: ${keyboardLanguages.data[kbLangKey].rtl ? 'right' : 'left'};
                }
            `}</style>

            <style jsx>{`
                .below-logo {
                    z-index: 2;
                }
                @media (min-width: 720px) and (max-width: 979px) {
                    .below-logo {
                        top: -53px;
                    }
                }

                @media (max-width: 440px) {
                    .freetext-sect {
                        margin: 0;
                    }
                }

                @media (min-width: 720px) and (max-width: 730px) {
                    .freetext-sect {
                        margin: 0;
                        padding: 0;
                    }
                }

                .hr-longer {
                    margin: 8px auto;
                    width: 830px;
                    height: 1px;
                    background: #264a6b;
                    border-bottom: 1px solid #7da4c3;
                    opacity: 0.4;
                    border-radius: 62px;
                }

                .upper-link-unit-respons {
                    height: 19px;
                    max-width: 728px;
                    text-align: center;
                    margin: 0 auto;
                    min-height: 19px;
                }

                @media (max-width: 728px) {
                    .upper-link-unit-respons {
                        max-width: 468px;
                    }
                    .layout {
                        width: 100%;
                    }
                    .stickyBuffer {
                        over-flow: hidden;
                        padding-top: 110px;
                    }
                }

                @media (max-width: 468px) {
                    .upper-link-unit-respons {
                        height: 90px;
                        width: 100%;
                    }

                    #upper-link-units-div .hr-longer {
                        display: none;
                    }
                }

                #upper-link-units-div {
                    text-align: center;
                    margin-bottom: 20px;
                }

                @media (min-width: 720px) and (max-width: 979px) {
                    #upper-link-units-div {
                        position: relative;
                    }
                }

                @media (max-width: 1075px) {
                    .layout {
                        margin-top: 20px;
                    }
                }

                .actionBarStickyBoundary :global(.sticky) {
                    z-index: 100;
                }
                @media (max-width: 960px) {
                    .actionBarStickyBoundary :global(.sticky) {
                        top: 48px !important;
                    }
                }
                @media (min-width: 1075px) {
                    .side_ads {
                        width: 300px;
                    }
                }

                .Gate2Home_upper_side_ad,
                .Gate2Home_lower_side_ad {
                    width: 300px;
                    height: 250px;
                }

                .Gate2Home_bottom_rect {
                    margin: 0 auto;
                    display: inline-block;
                    width: 336px;
                    height: 280px;
                }
            `}</style>

            <style jsx global>
                {`
                    .before_bottom_rect {
                        margin: 20px;
                    }
                `}
            </style>

            <KeyboardSectionLogoHeader kbLangKey={kbLangKey} i18n={i18n} />

            <div className='below-logo' role='main'>
                <div id='upper-link-units-div'>
                    <div className='hr-longer' />
                    <div className='upper-link-unit-respons'>
                        {/* <!-- upper_link_unit_respons --> */}
                        <AdsenseAdVisible
                            updateKey={kbLangKey + sectionKey + 'up'}
                            adSlot='8565706451'
                            adFormat='link'
                            isVisible={true}
                        />
                    </div>
                    <div className='hr-longer' />
                </div>

                <div className={'layout_grid_container'}>
                    {/* <!-- sky-left --> */}

                    <div className={'side_ads'}>
                        {mediaQueries.isLess1075 || !abtestVal ? (
                            <AdsenseAd
                                updateKey={kbLangKey + sectionKey + 'upper_side'}
                                parentClassName={'parent-ad-1075'}
                                className={'upper_side_ad'} //skyleft
                                adSlot='5635065255'
                                responsive={'false'}
                            />
                        ) : (
                            <div data-ad='Gate2Home_upper_side_ad' className='Gate2Home_upper_side_ad'></div>
                        )}

                        {/* {(!abtestVal || isLess1075) ? */}
                        {!mediaQueries.isLess1075 &&
                            (abtestVal ? (
                                <div data-ad='Gate2Home_lower_side_ad' className='Gate2Home_lower_side_ad'></div>
                            ) : (
                                <AdsenseAd
                                    updateKey={kbLangKey + sectionKey + 'lower_side'}
                                    parentClassName={`parent-side-ad`}
                                    adSlot='2402397259'
                                    className={`lower_side_ad`} //skyright
                                    responsive={'false'}
                                />
                            ))}
                    </div>

                    <div className='layout'>
                        <div className='stickyBoundary2'></div>
                        <SearchProvider>
                            <T13nProvider>
                                <TextboxProvider>
                                    <KeyboardProvider>
                                        {sectionKey === 'freetext' && <ActionBar {...{ i18n, kbLangKey }} />}
                                        {keyboardLanguages.data[kbLangKey].t13n && (
                                            <LazyLoadT13n kbLangKey={kbLangKey} />
                                        )}
                                        <div className='freetext-sect'>{cloneElement(children, props)}</div>
                                        <StickyOnDemand
                                            mode='bottom'
                                            style={{ opacity: '0.96' }}
                                            disabled={mediaQueries.isMobileMedia && !Gate2homeState.mobileLoadKeyboard}
                                            positionRecheckInterval={100}
                                            boundaryElement={'.stickyBoundary2'}
                                        >
                                            <div className={'stickyBuffer'}>
                                                {mediaQueries.isMobileMedia && !Gate2homeState.mobileLoadKeyboard && (
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: '116px',
                                                            backgroundColor: '#abb4be',
                                                            color: 'white',
                                                            paddingTop: '70px'
                                                        }}
                                                    >
                                                        <Button
                                                            color='primary'
                                                            variant='contained'
                                                            onClick={handleLoadKeyboard}
                                                        >
                                                            {t('UI.Load Keyboard')}
                                                        </Button>
                                                    </div>
                                                )}
                                                {/* <Suspense fallback={<Loader />}> */}
                                                {(!mediaQueries.isMobileMedia || Gate2homeState.mobileLoadKeyboard) && (
                                                    <VirtualKeyboard
                                                        layout2={keyboardLanguages.data[kbLangKey].layout}
                                                        kbLangKey={kbLangKey}
                                                        sectionKey={sectionKey}
                                                        mobileLoadKeyboard={Gate2homeState.mobileLoadKeyboard}
                                                        t={t}
                                                    />
                                                )}
                                                {/* </Suspense> */}
                                            </div>
                                        </StickyOnDemand>
                                    </KeyboardProvider>
                                </TextboxProvider>
                                {/* } */}
                            </T13nProvider>
                            {/* <Suspense fallback={<Loader />}> */}
                            <>
                                {sectionKey === 'google' && <GoogleResultsIframeLazy {...{ kbLangKey, q }} />}
                                {sectionKey === 'wiki' && <WikipediaResultsIframeLazy {...{ kbLangKey, q }} />}
                            </>
                            {/* </Suspense> */}
                        </SearchProvider>
                        <div className='before_bottom_rect'>
                            {/* <!-- bottom_rect336x228  --> */}

                            {mediaQueries.isLess1075 || !abtestVal ? (
                                <AdsenseAd
                                    updateKey={kbLangKey + sectionKey + 'botrec'}
                                    adSlot='7477043656'
                                    className='buttom_rect'
                                    adFormat={'auto'}
                                    // responsive={'false'}
                                />
                            ) : (
                                <div data-ad='Gate2Home_bottom_rect' className='Gate2Home_bottom_rect'></div>
                            )}
                        </div>
                    </div>
                </div>

                {/* <!-- below-ad-1075 --> */}
                {mediaQueries.isLess1075 && (
                    <AdsenseAd
                        parentClassName={'parent-ad-1075'}
                        updateKey={kbLangKey + sectionKey + 'below-ad-1075'}
                        className='below_ad_1075'
                        adSlot='5812047962'
                        responsive={'false'}
                    />
                )}
            </div>
            {isDesktopMedia && <IpadCSS />}
            {isMobileMedia && Gate2homeState.mobileLoadKeyboard && <MobileCSS />}
        </div>
    )
}

export default Keyboard
